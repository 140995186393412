<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import GenericModal from './GenericModal.vue';
import ButtonComponent from '../common/ButtonComponent.vue';
import ConfirmNewPassword from './auth/ConfirmNewPassword.vue';
import { rankingService } from '@/services/rankings.service';
import CardSkeleton from '@/components/skeletons/CardSkeleton.vue';
import { handleAxiosError } from '@/utils/error';

interface Props {
  modelValue?: boolean;
}

interface ModalStep {
  id: number;
  title: string;
  message: string;
  textFirstButton: string;
  textSecondButton: string;
  image: string;
}

interface TriggerSubmit {
  triggerSubmit: () => Promise<boolean>;
}

const props = defineProps<Props>();
const currentStep = ref(0);
const fromPasswordRef = ref<TriggerSubmit>();
const isPasswordRegistered = ref(false); // Para saber si ya tiene contraseña

const informationEdit = ref({
  title: 'Edit current password',
  titlePassword: 'Password',
  subtTitlePassword: 'Repeat password',
});
const isLoading = ref(false);
const isLoadingButton = ref(false);

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const steps: ModalStep[] = [
  {
    id: 1,
    title: 'My Gaming Passport',
    message:
      'Use your password and activate your Game Passport in hundreds of games. Earn points, compete and win great prizes. 🏆',
    textFirstButton: 'Close',
    textSecondButton: 'Save password',
    image: 'https://storage.googleapis.com/legendaryum-image/Dragon2',
  },
  {
    id: 2,
    title: 'Set Your Password',
    message: 'Create a password to activate your Gaming Passport. 🏆',
    textFirstButton: 'Cancel',
    textSecondButton: 'Save password',
    image:
      'https://storage.googleapis.com/legendaryum-image/Product-Game-SkullSquadCityWeb',
  },
];

const currentTitle = computed(() => steps[currentStep.value].title);
const currentMessage = computed(() => steps[currentStep.value].message);
const currentImage = computed(() => steps[currentStep.value].image);

const textFirstButton = computed(
  () => steps[currentStep.value].textFirstButton,
);

const textSecondButton = computed(
  () => steps[currentStep.value].textSecondButton,
);

async function checkPasswordStatus() {
  isLoading.value = true; // Oculta el contenido mientras carga
  try {
    await rankingService.verifyPasswordRanking();
    isPasswordRegistered.value = true;
    currentStep.value = isPasswordRegistered.value ? 0 : 1;
  } catch (error) {
    const { status } = handleAxiosError(error);
    if (status === '404') {
      currentStep.value = isPasswordRegistered.value ? 0 : 1;
    }
  } finally {
    isLoading.value = false;
  }
}
watch(
  () => props.modelValue,
  async (newValue) => {
    if (newValue) {
      await checkPasswordStatus();
    }
  },
);

function handleFirstButton() {
  emit('update:modelValue', false);
}

async function handleSecondButtonSubmitPassword() {
  await fromPasswordRef.value?.triggerSubmit();
}
</script>

<template>
  <GenericModal
    v-model="show"
    container-class="bg-primary-gradient max-w-lg "
    :hide-close-button="false"
    :title="currentTitle"
    :close-to-click-outside="false"
    title-class-container="flex flex-col justify-center"
    title-class="font-bold text-2xl"
  >
    <template #default>
      <template v-if="!isLoading">
        <p class="pb-4 text-lg font-normal text-white">{{ currentMessage }}</p>

        <div
          class="relative flex size-full flex-col items-center justify-center"
        >
          <div
            class="absolute inset-0 size-full rounded-lg bg-cover bg-center"
            :style="{ backgroundImage: `url(${currentImage})` }"
          >
            <div class="absolute inset-0 rounded-lg bg-[#5B1DAA]/90"></div>
          </div>
          <div class="relative z-10 h-[280px] w-[276px] rounded-lg">
            <ConfirmNewPassword
              ref="fromPasswordRef"
              v-model="show"
              :is-edit="isPasswordRegistered"
              :information-edit="informationEdit"
              :is-loading-button="isLoadingButton"
              @update:is-loading-button="isLoadingButton = $event"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col gap-6 pt-4">
          <CardSkeleton :height="61" />
          <CardSkeleton :height="280" />
        </div>
      </template>
    </template>

    <template #footer>
      <div class="flex justify-between gap-6">
        <ButtonComponent
          variant="secondary"
          class="w-full"
          height="md"
          width="none"
          font-size="base"
          tabindex="-1"
          :disabled="isLoadingButton"
          @click="handleFirstButton"
        >
          {{ textFirstButton }}
        </ButtonComponent>

        <ButtonComponent
          variant="secondary"
          class="w-full text-xl"
          height="md"
          width="none"
          font-size="base"
          :is-loading="isLoadingButton"
          @keydown.enter="handleSecondButtonSubmitPassword"
          @click="handleSecondButtonSubmitPassword"
        >
          {{ textSecondButton }}
        </ButtonComponent>
      </div>
    </template>
  </GenericModal>
</template>

<style scoped>
.title-container {
  display: block !important;
}
</style>
