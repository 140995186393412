<script setup lang="ts">
import { computed, ref } from 'vue';

const showPassword = ref(false);

interface Props {
  modelValue: string;
  error: boolean;
  title: string;
  inputType: string;
  name?: string;
  errorMessage?: string;
  placeholder?: string;
  labelClass?: string;
  viewPassword?: boolean;
}

const emits = defineEmits<{
  (e: 'update:modelValue', modelValue: string): string;
  (e: 'update', modelValue: string): string;
  (e: 'clearErrors'): void;
}>();

const props = defineProps<Props>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
    emits('update', value);
    emits('clearErrors');
  },
});

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <div class="relative">
    <label :for="name" :class="labelClass" class="mb-1 block text-sm">{{
      title
    }}</label>
    <input
      v-model="value"
      :type="inputType === 'password' && !showPassword ? inputType : 'text'"
      class="h-9 w-full rounded-md border-2 p-4"
      :name="name"
      :class="{
        'border-2 bg-white text-black': !error,
        'border-2 border-red-500 bg-white text-red-700': error,
      }"
      required
      :placeholder="placeholder"
      maxlength="50"
    />
    <span
      v-if="props.viewPassword"
      class="absolute -right-8 top-7"
      @click="togglePasswordVisibility"
    >
      <IconifyIcon
        v-if="!showPassword"
        icon="weui:eyes-off-outlined"
        class="cursor-pointer text-gray-600"
        width="24"
        height="24"
        style="stroke: white; stroke-width: 1"
      />
      <IconifyIcon
        v-else
        icon="weui:eyes-on-outlined"
        class="cursor-pointer text-gray-600"
        width="24"
        height="24"
        style="stroke: white; stroke-width: 1"
      />
    </span>
    <div>
      <p v-if="error" class="top-15 absolute right-0 text-sm text-red-500">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>
