<script setup lang="ts">
import { computed, ref } from 'vue';
import GenericModal from './GenericModal.vue';
import ButtonComponent from '../common/ButtonComponent.vue';
import ConfirmNewPassword from './auth/ConfirmNewPassword.vue';
import { useRouter } from 'vue-router';
import useCheckout from '@/composables/useCheckout';

interface Props {
  modelValue?: boolean;
  message: string;
  title: string;
}

interface ModalStep extends Props {
  id: number;
  isVisibleButton: boolean;
  textFirstButton: string;
  textSecondButton: string;
  image: string;
  isFromChangePassword: boolean;
  isVisibleImage: boolean;
  isSecondButton?: boolean;
}
interface TriggerSubmit {
  triggerSubmit: () => Promise<boolean>;
}

const props = defineProps<Props>();
const currentStep = ref(0);
const fromPasswordRef = ref<TriggerSubmit>();
const router = useRouter();
const isWelcomePasswordModal = ref(true);
const isLoadingButton = ref(false);
const { getRewardAmount, rewardAmount } = useCheckout();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const steps: ModalStep[] = [
  {
    id: 1,
    title: 'Welcome to Legendaryum',
    message:
      'Enjoy amazing games, live unique experiences and win prizes in missions and tournaments. The adventure begins! 🚀',
    isVisibleButton: false,
    textFirstButton: '',
    textSecondButton: 'Next',
    image: 'https://storage.googleapis.com/legendaryum-image/Dragon2',
    isFromChangePassword: false,
    isVisibleImage: false,
  },
  {
    id: 2,
    title: 'Gaming Passport',
    message:
      'Create your password and activate your Gaming Passport. Earn points, compete in hundreds of games and win great prizes. 🏆',
    isVisibleButton: true,
    textFirstButton: 'Create later',
    textSecondButton: 'Save and continue',
    image:
      'https://storage.googleapis.com/legendaryum-image/Product-Game-SkullSquadCityWeb',
    isFromChangePassword: true,
    isVisibleImage: false,
    isSecondButton: true,
  },
  {
    id: 3,
    title: 'Welcome gift',
    message: '',
    isVisibleButton: true,
    textFirstButton: 'Create later',
    textSecondButton: 'Create island now',
    image: '/images/bg-rankings-card.webp',
    isFromChangePassword: false,
    isSecondButton: false,
    isVisibleImage: true,
  },
];

const currentTitle = computed(() => steps[currentStep.value].title);
const currentMessage = computed(() => {
  return steps[currentStep.value].message;
});
const currentButton = computed(() => steps[currentStep.value].isVisibleButton);
const currentImage = computed(() => steps[currentStep.value].image);
const currentIsVisibleImage = computed(
  () => steps[currentStep.value].isVisibleImage,
);
const textFirstButton = computed(
  () => steps[currentStep.value].textFirstButton,
);
const currentIsFromChangePassword = computed(
  () => steps[currentStep.value].isFromChangePassword,
);
const textSecondButton = computed(
  () => steps[currentStep.value].textSecondButton,
);

const currentIsSecondButton = computed(
  () => steps[currentStep.value].isSecondButton,
);

const stepColor = ref(
  [1, 2, 3].map((id, index) => ({
    idColor: id,
    colorStep: index === 0 ? '#019E11' : '#BABABA',
  })),
);

function nextStepSecondButton() {
  stepColor.value[currentStep.value + 1].colorStep = '#019E11';
  currentStep.value++;
}

async function triggerNextStep() {
  if (currentStep.value === 0) {
    await getRewardAmount();
  }
  if (currentIsFromChangePassword.value) {
    if (currentStep.value === 2) {
      nextStepSecondButton();
    }
    await fromPasswordRef.value?.triggerSubmit();
  } else {
    if (currentIsSecondButton.value === false) {
      emit('update:modelValue', false);

      await router.push('/user/rooms');
    }
    nextStepSecondButton();
  }
}

async function nextStepFirstButton() {
  if (currentIsSecondButton.value) {
    nextStepSecondButton();
  } else {
    await router.push('/');
    emit('update:modelValue', false);
  }
}
</script>

<template>
  <GenericModal
    v-model="show"
    container-class="bg-primary-gradient max-w-lg flex flex-col item-center"
    :title="currentTitle"
    :close-to-click-outside="false"
    :hide-close-button="false"
    title-class-container="flex flex-col justify-center"
    title-class="font-bold text-2xl"
    :current-color-step="stepColor"
  >
    <template #default>
      <div v-if="currentStep === 2" class="pb-4 text-lg font-normal text-white">
        <p>
          Congratulations, you've received
          {{ rewardAmount.registerReward }} Legends Coins as a gift 🪙 to create
          your Home, Virtual World, and Immersive Hall of Fame.
        </p>
        <p>Enjoy the experience and share it with all your friends!💎</p>
      </div>
      <p v-else class="pb-4 text-lg font-normal text-white">
        {{ currentMessage }}
      </p>
      <div
        v-if="!currentIsFromChangePassword && !currentIsVisibleImage"
        class="h-[260px] max-w-lg text-base"
      >
        <img
          class="size-full rounded-lg object-cover"
          :src="currentImage"
          alt=""
        />
      </div>

      <div
        v-else-if="currentIsFromChangePassword"
        class="relative flex size-full flex-col items-center justify-center"
      >
        <div
          class="absolute inset-0 size-full rounded-lg bg-cover bg-center"
          :style="{ backgroundImage: `url(${currentImage})` }"
        >
          <div class="absolute inset-0 rounded-lg bg-[#5B1DAA]/90"></div>
        </div>
        <div class="relative z-10 h-[260px] w-[276px] rounded-lg">
          <ConfirmNewPassword
            ref="fromPasswordRef"
            :is-welcome-password-modal="isWelcomePasswordModal"
            :on-next-step-second-button="nextStepSecondButton"
            :is-loading-button="isLoadingButton"
            @update:is-loading-button="isLoadingButton = $event"
          />
        </div>
      </div>

      <div
        v-else
        class="relative flex h-[260px] w-[420px] items-center justify-center overflow-hidden"
      >
        <div class="size-full">
          <img
            :src="currentImage"
            alt=""
            class="absolute inset-0 h-full w-[800px] rounded-lg object-cover"
          />
          <div
            class="absolute inset-0 flex flex-col items-center justify-center rounded-lg bg-[#5B1DAA]/70"
          >
            <div class="relative h-[89.36px] w-[164px]">
              <img
                class="absolute left-1/2 top-1/4 w-[120px] -translate-x-1/2 -translate-y-1/2"
                src="https://storage.googleapis.com/legendaryum-image/email_coins_pile"
                alt=""
              />
              <p
                class="absolute -bottom-3 left-2 text-[22px] font-bold text-white"
              >
                {{ rewardAmount.registerReward }} Legends
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-between gap-6">
        <ButtonComponent
          variant="secondary"
          class="w-full"
          :class="currentButton ? 'block' : 'invisible'"
          height="md"
          width="none"
          font-size="base"
          :disabled="isLoadingButton"
          @click="nextStepFirstButton"
        >
          {{ textFirstButton }}
        </ButtonComponent>

        <ButtonComponent
          variant="secondary"
          class="w-full text-xl"
          height="md"
          width="none"
          font-size="base"
          :is-loading="isLoadingButton"
          @click="triggerNextStep"
        >
          {{ textSecondButton }}
        </ButtonComponent>
      </div>
    </template>
  </GenericModal>
</template>

<style scoped>
.title-container {
  display: block !important;
}
</style>
