import { useDirectMessageStore } from '@/stores/directMessage';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import type {
  SocketClientToServer,
  SocketServerToClient,
} from './rapid-messaging.socket.types';
import { toast } from '@/utils/toast';
import { QueryClient } from '@tanstack/vue-query';
import { socialService } from '.';
import type {
  DMChat,
  DM,
  RoomChatMessageRecord,
  MinimizedSocialUser,
} from './rapid-messaging.service.types';

const queryClient = new QueryClient();

let rapidMessagingSocket:
  | Socket<SocketServerToClient, SocketClientToServer>
  | undefined;

export const connectRapidMessagingSocket = (userId?: string) => {
  if (rapidMessagingSocket) return rapidMessagingSocket;

  const directMessageStore = useDirectMessageStore();

  const url = new URL(import.meta.env.VITE_API_RAPID_MESSAGING_URL);

  rapidMessagingSocket = io(url.origin, {
    reconnectionDelay: 500,
    reconnectionDelayMax: 1000,
    path: `${url.pathname}socket.io`,
    withCredentials: true,
    // Se puede fijar el transporte a "websocket",
    // polling (y otros transportes) necesita sticky sessions.
    // https://socket.io/docs/v4/using-multiple-nodes/#sticky-load-balancing
    // Se usa sticky sessions con destination rules
    // transports: ['websocket'],
  });

  rapidMessagingSocket.on('connect', () => {
    console.log('Connected to rapid-messaging socket: ', userId);

    rapidMessagingSocket?.emit('joinRoom', {
      userId: userId ?? '',
    });
  });

  rapidMessagingSocket.on('connect_error', (error: { message: string }) => {
    console.error('Error connecting to rapid-messaging socket:', error);
  });

  rapidMessagingSocket.on(
    'newMessage',
    async (data: { message: DM; chat: DMChat }) => {
      await directMessageStore.addMessage(data.message, data.chat);
    },
  );

  rapidMessagingSocket.on(
    'newMessageInRoomChat',
    (data: { message: RoomChatMessageRecord }) => {
      directMessageStore.addRoomMessage(
        data.message._id,
        data.message.content,
        data.message.chatId,
        data.message.minimizedSocialUser,
        data.message.createdAt,
      );
    },
  );

  rapidMessagingSocket.on('successMessage', async () => {
    await directMessageStore.getChats();
  });

  rapidMessagingSocket.on('newMessageNotification', async (message) => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['social-user', message.userId],
        queryFn: () => {
          return socialService.getUserById(message.userId);
        },
      });
      directMessageStore.showNotification(message, data);
    } catch (error) {
      console.error(error);
    }
  });

  rapidMessagingSocket.on(
    'connectedUsersInRoom',
    (data: { users: MinimizedSocialUser[] }) => {
      directMessageStore.roomUsers = data.users;
    },
  );

  rapidMessagingSocket.on('errorMessage', (error) => {
    console.error('errorMessage', error.message);

    toast.error({
      title: `There was an error processing the request`,
      message: error.message,
    });
  });

  rapidMessagingSocket.on('disconnect', (reason, description) => {
    console.log(
      'Connection to rapid-messaging  socket closed:',
      reason,
      description,
    );

    rapidMessagingSocket = undefined;
    //Closing Status Codes:
    //
    // 1000 (Normal Closure): The connection was closed cleanly and intentionally. No reconnection needed.
    // 1001 (Going Away): The endpoint is going away (e.g., server shutting down). Reconnection might be possible later.
    // 1006 (Abnormal Closure): The connection was closed unexpectedly (e.g., network error). Reconnection is recommended.
    // 1011 (Internal Error): An unexpected condition prevented the server from fulfilling the request. Reconnection is worth attempting.
  });

  return rapidMessagingSocket;
};
