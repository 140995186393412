import { usePaymentsStore } from '@/stores/payments';
import { type Product, type RegisterReward, paymentsService } from '@/services';
import { toast } from '@/utils/toast';
import { loadStripe } from '@stripe/stripe-js';
import { ref } from 'vue';

const useCheckout = () => {
  const rewardAmount = ref<RegisterReward>({ registerReward: '' });

  const paymentsStore = usePaymentsStore();

  function handleCheckout(product: Product) {
    paymentsStore.selectedProduct = product;

    paymentsStore.showCheckoutModal = true;
  }

  async function redirectToStripe() {
    const product = paymentsStore.selectedProduct;

    if (!product) {
      console.error('Product was not set for checkout process.');
      return;
    }

    try {
      // 1. Fetch Stripe Keys
      const {
        data: { publishableKey },
      } = await paymentsService.publishableKey();
      const stripe = await loadStripe(publishableKey);

      // 2. Handle Stripe Loading Errors
      if (!stripe) {
        console.error('Stripe.js has not loaded');
        return;
      }
      const { data } = await paymentsService.createSession({
        // solo me sirve el priceId
        priceId: product.default_price.id,
        // SusccesUrl y CancelUrl son para los checkouts: es decir: packs, y la suscripción por primera vez
        successUrl: `${window.location.origin}${window.location.pathname}?checkout=product&product=${product.default_price.id}&status=success`,
        cancelUrl: `${window.location.origin}${window.location.pathname}?checkout=product&product=${product.default_price.id}&status=cancel`,
        // ReturnUrl es para cambiar el plan de suscripción y me arrepentí
        subscriptionReturnUrl: `${window.location.origin}${window.location.pathname}?checkout=subscription&status=cancel`,
        // ReturnUrl es para cambiar el plan de suscripción y fue un exito!!
        subscriptionSuccessUrl: `${window.location.origin}${window.location.pathname}?checkout=subscription&status=success`,
      });
      // Estoy cambiando el plan de suscripción
      if (data.billingPortalSessionUrl) {
        window.location.href = data.billingPortalSessionUrl;
        return;
      }
      //Estoy comprando packs, o suscribiendome por primera vez
      if (data.checkoutSessionId) {
        await stripe.redirectToCheckout({
          sessionId: data.checkoutSessionId,
        });
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      toast.error({
        title: 'There was an error while starting the payment process.',
        message: error as string,
      });
      throw new Error('Error during checkout');
    }
  }

  async function cancelSubscription() {
    try {
      // Así no use "stripe" lo tengo que importar y cargar
      // 1. Fetch Stripe Keys
      const {
        data: { publishableKey },
      } = await paymentsService.publishableKey();
      const stripe = await loadStripe(publishableKey);

      // 2. Handle Stripe Loading Errors
      if (!stripe) {
        console.error('Stripe.js has not loaded');
        return;
      }
      const { data } = await paymentsService.cancelSubscription({
        subscriptionReturnUrl: `${window.location.origin}${window.location.pathname}?checkout=cancel&status=cancel`,
        subscriptionSuccessUrl: `${window.location.origin}${window.location.pathname}?checkout=cancel&status=success`,
      });
      // Estoy cambiando el plan de suscripción
      if (data.billingPortalSessionUrl) {
        window.location.href = data.billingPortalSessionUrl;
        return;
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      toast.error({
        title: 'There was an error while starting the cancellation process.',
        message: error as string,
      });
    }
  }

  async function stopPendingCancellation() {
    try {
      await paymentsService.stopPendingCancellation();
    } catch (error) {
      console.error('Error stopping pending cancellation:', error);
      toast.error({
        title: 'There was an error while resuming your monthly subscription.',
        message: error as string,
      });
    }
    // reload all
    window.location.href = `${window.location.origin}${window.location.pathname}?checkout=resume&status=success`;
  }

  async function getRewardAmount() {
    try {
      const { data } = await paymentsService.rewardAmount();
      rewardAmount.value = data;
    } catch (error) {
      console.error('Error getting reward amount:', error);
      toast.error({
        title: 'There was an error while getting the reward amount.',
        message: error as string,
      });
    }
  }

  return {
    //data
    rewardAmount,
    //methods
    getRewardAmount,
    handleCheckout,
    redirectToStripe,
    cancelSubscription,
    stopPendingCancellation,
  };
};

export default useCheckout;
