import {
  experimental_createPersister,
  type AsyncStorage,
} from '@tanstack/query-persist-client-core';
import {
  QueryClient,
  // type QueryKey,
  type VueQueryPluginOptions,
} from '@tanstack/vue-query';
import { get, set, del, createStore, type UseStore } from 'idb-keyval';

function newIdbStorage(idbStore: UseStore): AsyncStorage {
  return {
    getItem: async (key) => await get(key, idbStore),
    setItem: async (key, value) => {
      await set(key, value, idbStore);
    },
    removeItem: async (key) => {
      await del(key, idbStore);
    },
  };
}

/*type CustomPersister = <T, TQueryKey extends QueryKey>(
  queryFn: (context: {
    queryKey: TQueryKey;
    signal: AbortSignal;
    meta: Record<string, unknown> | undefined;
    pageParam?: unknown;
    direction?: unknown;
  }) => T | Promise<T>,
  context: {
    queryKey: QueryKey;
    signal: AbortSignal;
    meta: Record<string, unknown> | undefined;
    pageParam?: unknown;
    direction?: unknown;
  },
  query: unknown,
) => Promise<T>;*/

const persister = experimental_createPersister({
  filters: {
    predicate(query) {
      return (query.meta?.persist ?? true) as boolean;
    },
  },
  storage: newIdbStorage(createStore('query', 'client')),
  maxAge: 1000 * 60 * 60 * 12, // 12 hours
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 1,
      staleTime: 1000 * 60 * 5,
      persister: persister,
    },
  },
});

export const vueQueryOptions = {
  enableDevtoolsV6Plugin: true,
  queryClient: queryClient,
} satisfies VueQueryPluginOptions;
